<script setup>
import { inject, computed, ref, watch } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import { HaModal, HaButton, HaFormField } from '@ha/components'
import { cleanInputStringNumber } from '@/helpers/formUtils'
import useRouteParams from '@/composables/useRouteParams'
import useStoreData from '@/composables/useStoreData'
import AnimatedIllustration from '@/components/voluntary-contributions/partials/AnimatedIllustration.vue'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import RangeSelector from '@/components/voluntary-contributions/partials/RangeSelector.vue'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'

const emit = defineEmits(['close'])

const props = defineProps({
  isCheckout: Boolean,
  tiersAmount: Number,
  paymentInstallmentsNumber: [Number, String]
})

const isWidget = inject('isWidget')
const { formType } = useRouteParams()
const { organization } = useStoreData()
const { i18n } = useContext()
const {
  tipSelectedInEuros,
  tipSuggestedInEuros,
  updateTipSelectedInEuros,
  tipSelected,
  tipSuggested
} = useVoluntaryContribution()
const { trackingMethods } = useTracking()
const currentTipSelectedInEuros = ref(
  parseFloat(tipSelectedInEuros.value) >= 0
    ? parseFloat(tipSelectedInEuros.value).toFixed(2)
    : tipSuggestedInEuros.value.default
)

const { isPaymentTermsEnabled } = useModalities()

const isTipDisabled = ref(!currentTipSelectedInEuros.value)

const parsedTipSelectedInEuro = computed(() =>
  currentTipSelectedInEuros.value ? parseFloat(currentTipSelectedInEuros.value) : 0
)

const bankTransactionCostInEuros = computed(() => functions.convertToEuros(props.tiersAmount / 100))
const showCatchUp = computed(() => {
  return (
    currentTipSelectedInEuros.value &&
    parsedTipSelectedInEuro.value <= 0 &&
    props.tiersAmount >= 10000
  )
})
const dataUxSaveButton = computed(
  () =>
    `Forms_${formType.value.toUpperCase()}${
      isWidget ? 'Widget' : ''
    }_Modal_ContributionSelection_SaveContribution`
)

const tipTracking = eventName => {
  const tipData = {
    tipSuggestion: tipSuggested.value.default,
    tipSuggestionMax: tipSuggested.value.max,
    tipSuggestionMin: tipSuggested.value.min,
    tipChosen: tipSelected.value
  }
  trackingMethods.onTipsUpdate(eventName, tipData, {
    payment_amount: parseFloat(functions.convertToEuros(props.tiersAmount)).toFixed(2),
    payment_frequency_type: props.paymentInstallmentsNumber,
    contribution_default_amount: parseFloat(
      functions.convertToEuros(tipSuggested.value.default)
    ).toFixed(2),
    contribution_amount: parseFloat(functions.convertToEuros(tipSelected.value)).toFixed(2)
  })
}
const onSaveAndUpdate = newTipSelected => {
  updateTipSelectedInEuros(newTipSelected, isPaymentTermsEnabled.value)
  tipTracking('Tips Catch Up')
  emit('close')
}
const onSave = () => {
  if(parseFloat(tipSelectedInEuros.value) !== parseFloat(parsedTipSelectedInEuro.value)) {
    updateTipSelectedInEuros(parsedTipSelectedInEuro.value,isPaymentTermsEnabled.value)
    tipTracking('Tips Submitted')
  }
  emit('close')
}
const onTipUpdate = value => {
  currentTipSelectedInEuros.value = value ? cleanInputStringNumber(value) : ''
}

const highDonationThreshold = ref(6)
const midDonationThreshold = ref(2)

const status = computed(() => {
  const parsedTip = parseFloat(currentTipSelectedInEuros.value)
  switch (true) {
    case parsedTip > highDonationThreshold.value:
      return 'highDonation'
    case parsedTip > midDonationThreshold.value && parsedTip <= highDonationThreshold.value:
      return 'midDonation'
    case parsedTip <= midDonationThreshold.value && parsedTip > 0:
      return 'lowDonation'
    case currentTipSelectedInEuros.value === '':
    default:
      return 'noDonation'
  }
})

const getContent = (key) =>  i18n.t(`modal.contributionSelection.newModal.${key}.${status.value}`, [organization.value.name])

watch(currentTipSelectedInEuros, () => {
  isTipDisabled.value = !!currentTipSelectedInEuros.value && parsedTipSelectedInEuro.value <= 0
})
</script>

<template>
  <HaModal
    class="contribution-modal-modify"
    @close="onSave(); emit('close')"
    data-testid="contribution-modal"
  >
    <template #header>
      {{ $t(`modal.contributionSelection.newModal.title`) }}
    </template>
    <template #body>
      <div class="contribution-modal-modify__body">
        <AnimatedIllustration :tip-selected="currentTipSelectedInEuros" :status="status" />
        <p class="contribution-modal-modify__catch-phrase" v-html="getContent('catchPhrase')"></p>
        <HaFormField class="contribution-modal-modify__field">
          <RangeSelector
            :tip="currentTipSelectedInEuros"
            :tip-suggestions="tipSuggestedInEuros"
            @update:tip="onTipUpdate"
          />
        </HaFormField>
        <p class="contribution-modal-modify__text" v-html="getContent('textImage')"></p>
      </div>
    </template>
    <template #footer>
      <template v-if="showCatchUp">
        <HaButton
          fluid
          :data-ux="dataUxSaveButton"
          data-testid="button-validate-retake"
          @click="onSaveAndUpdate(0)"
        >
          {{ $t('modal.contributionSelection.newModal.catchUpTips.noThankYou') }}
        </HaButton>
        <HaButton
          fluid
          variant="outline"
          :data-ux="dataUxSaveButton"
          data-testid="button-no-retake"
          @click="onSaveAndUpdate(bankTransactionCostInEuros)"
        >
          {{
            $t('modal.contributionSelection.newModal.catchUpTips.addTips', [
              bankTransactionCostInEuros
            ])
          }}
        </HaButton>
      </template>
      <HaButton v-else fluid :data-ux="dataUxSaveButton" data-testid="button-save" @click="onSave">
        {{ $t('modal.contributionSelection.newModal.validate') }}
      </HaButton>
    </template>
  </HaModal>
</template>

<style lang="scss" scoped>
.contribution-modal-modify {
  font-size: $ha-font-size-small;

  &__body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__catch-phrase {
    /* stylelint-disable-next-line */
    height: 2rem;
    color: var(--ha-color-iris);
    font-weight: bold;
    font-size: $ha-font-size-big;
    text-align: center;
  }

  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: $ha-spacing-small;
  }

  &__text {
    align-content: center;
    height: $ha-spacing-jumbo * 2;
    margin-top: $ha-spacing-large;
  }

  &__catch-up {
    margin-top: $ha-spacing-medium;

    p {
      color: var(--ha-color-text);
    }
  }

  :deep(.HaModal--Wrapper) {
    @include mediaQuery(600, 'max') {
      max-height: 100% !important;
    }

    .HaFormField--Field {
      width: 100%;
    }

    .HaModal--Footer {
      flex-direction: column;

      @include mediaQuery(600) {
        /* stylelint-disable-next-line */
        padding: 0 2rem 1rem;
      }


      button {
        margin-inline: 0;
        margin-bottom: $ha-spacing-medium;
        color: var(--ha-color-white) !important;
        background-color: var(--ha-color-iris) !important;
        border-color: var(--ha-color-white) !important;
      }

      button:last-child {
        @include mediaQuery(600, 'max') {
          margin-bottom: 0;
        }
      }
    }

    .HaModal--Content {
      overflow: hidden;
    }

    .HaModal--Header {
      font-size: $ha-font-size-large;
    }
  }

}
</style>

